import Image from 'next/image';
import Box from '@mui/material/Box';

const HomeSliderImage = ({slide, idx}) => {
    return (
        <Box 
            sx={{ 
                position: 'relative', 
                width: '100%', 
                height: 'auto', 
                paddingBottom: '18%' ,
                '@media (max-width: 550px)' : {
                height: '65px'
                },
            }}
        > 
            <Image 
                key={slide.image}
                src={slide.image}
                alt={slide.name}
                fill
                style={{ objectFit: 'cover' }} 
                quality={100}
                priority={idx === 0}
            />
        </Box>
    );
};

export default HomeSliderImage;
